<template>
  <v-container fluid>
    <v-row>
      <v-col md="12" cols="12" class="d-flex justify-space-between">
        <div class="conOfHeader">
          <div class="headerOfPage">Tags</div>
        </div>
        <div class="conOfHeader text-right pt-3">
          <div class="btnAdd">
            <v-btn
              class="btnAddPrimary"
              :disabled="isLoadingSave"
              :loading="isLoadingSave"
              @click="dialogTag = true"
            >
              <v-icon>mdi-plus</v-icon> Add Tag
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-row>
        <v-col cols="12">
          <section class="conOfProjects">
            <div class="conOfTable">
              <v-data-table
                :headers="headers"
                :items="listData"
                hide-default-footer
              >
              </v-data-table>
            </div>
          </section>
          <div class="conOfPagination pr-5 pt-5">
            <v-pagination
              v-model="pagination.current_page"
              :total-visible="7"
              :length="pagination.last_page"
              @input="goToPage(pagination.current_page)"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-row>
    <v-dialog v-model="dialogTag" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">ADD Tag</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row class="conFormSubmit d-flex justify-center">
              <v-col cols="12">
                <div class="pt-3">
                  <label class="eachLabel">Tag Name</label>

                  <v-text-field
                    v-model="formItem.name"
                    outlined
                    item-text="name"
                    item-value="id"
                    placeholder="Tags"
                    hide-details="auto"
                  >
                  </v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="dialogTag = false">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="addTag()"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    formItem: {
      name: "",
    },
    pagination: {
      current_page: 1,
    },
    dialogTag: false,
    projectList: [],
    listData: [],
    headers: [
      { text: "Tag Name", value: "name" },
      { text: "Created By", value: "created_by" },
      { text: "Created At", value: "created_at" },
    ],
    isLoadingSave: false,
  }),
  computed: {},
  methods: {
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      apiServices.get(`tags?per_page=7&page=${page}`).then((res) => {
        if (res) {
          if (res.data.length > 0) {
            this.isLoadingSearch = false;
            this.listData = res.data;
            console.log("tasks", this.listData);
            this.pagination = res.meta;
            console.log("pagination", this.pagination);
          }
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    addTag() {
      const errors = [];
      this.isLoadingSave = true;
      apiServices.post("tags", this.formItem).then((res) => {
        if (res) {
          this.isLoadingSave = false;
          this.getListData();
          this.formItem = "";
          this.dialogTag = false;
        } else {
          this.isLoadingSave = false;
          return errors;
        }
      });
    },
  },
  created() {
    this.getListData();
  },
  watch: {},
};
</script>
<style lang="scss" scoped></style>
